<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <DatabaseBackupForm
      v-if="!isLoading"
      :databaseBackupData="databaseBackup"
      :submitName="$t('add')"
      v-on:addDatabaseBackup="addDatabaseBackup()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import DatabaseBackupForm from "@/components/databaseBackups/DatabaseBackupForm.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import DatabaseBackup from "@/models/databaseBackups/DatabaseBackup";
import { downloadFileFromServer } from "@/utils/functions";

export default {
  name: "DatabaseBackupAdd",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    DatabaseBackupForm,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      databaseBackup: new DatabaseBackup(),
      autoDownloadStatus: "",
    };
  },
  methods: {
    downloadDatabaseBackup(Path) {
      try {
        downloadFileFromServer(this.language, this.userAuthorizeToken, Path);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
      }
    },
    async addDatabaseBackup() {
      this.isLoading = true;
      this.autoDownloadStatus = this.databaseBackup.autoDownload;

      try {
        let response = await this.databaseBackup.addDatabaseBackup(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          if (this.autoDownloadStatus) {
            this.downloadDatabaseBackup(response.data.databaseBackupPath);
          }
          this.databaseBackup.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
