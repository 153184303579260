import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../general/PaginationData";
import DatabaseBackupImg from "@/assets/images/DatabaseBackups.svg";
import DatabaseBackupsFilter from "./DatabaseBackupsFilter";

export default class DatabaseBackup {
  defaultImg = DatabaseBackupImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.databaseBackupToken = "";
    this.databaseBackupNameCurrent = "";
    this.databaseBackupNameAr = "";
    this.databaseBackupNameEn = "";
    this.databaseBackupNameUnd = "";
    this.databaseBackupDescriptionCurrent = "";
    this.databaseBackupDescriptionAr = "";
    this.databaseBackupDescriptionEn = "";
    this.databaseBackupDescriptionUnd = "";
    this.databaseBackupNotes = "";
    this.databaseBackupPath = "";
    this.employeeTokenCerateAction = "";
    this.employeeNameCurrentCerateAction = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.databaseBackupArchiveStatus = "";
    this.autoDownload = false;
  }
  fillData(data) {
    this.databaseBackupToken = data.databaseBackupToken;
    this.databaseBackupNameCurrent = data.databaseBackupNameCurrent;
    this.databaseBackupNameAr = data.databaseBackupNameAr;
    this.databaseBackupNameEn = data.databaseBackupNameEn;
    this.databaseBackupNameUnd = data.databaseBackupNameUnd;
    this.databaseBackupDescriptionCurrent =
      data.databaseBackupDescriptionCurrent;
    this.databaseBackupDescriptionAr = data.databaseBackupDescriptionAr;
    this.databaseBackupDescriptionEn = data.databaseBackupDescriptionEn;
    this.databaseBackupDescriptionUnd = data.databaseBackupDescriptionUnd;
    this.databaseBackupNotes = data.databaseBackupNotes;
    this.databaseBackupPath = data.databaseBackupPath;
    this.employeeTokenCerateAction = data.employeeTokenCerateAction;
    this.employeeNameCurrentCerateAction = data.employeeNameCurrentCerateAction;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.databaseBackupArchiveStatus = data.databaseBackupArchiveStatus;
  }

  async getAllDatabaseBackups(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new DatabaseBackupsFilter()
  ) {
    try {
      let response = await axios.get(
        `/DatabaseBackups/GetAllDatabaseBackups?language=${language}&userAuthorizeToken=${userAuthorizeToken}&DatabaseBackupArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${filterData.textSearch}&dateFrom=${filterData.dateFrom}&dateTo=${filterData.dateTo}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addDatabaseBackup(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      databaseBackupToken: this.databaseBackupToken,
      databaseBackupDescriptionAr: this.databaseBackupDescriptionAr,
      databaseBackupDescriptionUnd: this.databaseBackupDescriptionUnd,
      databaseBackupNotes: this.databaseBackupNotes,
    };

    try {
      return await axios.post(`/DatabaseBackups/AddDatabaseBackup`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveDatabaseBackup(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      databaseBackupToken: this.databaseBackupToken,
    };

    try {
      return await axios.post(
        `/DatabaseBackups/FinalDeleteDatabaseBackup`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
