import { render, staticRenderFns } from "./DatabaseBackupAdd.vue?vue&type=template&id=630d3ae6&scoped=true&"
import script from "./DatabaseBackupAdd.vue?vue&type=script&lang=js&"
export * from "./DatabaseBackupAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630d3ae6",
  null
  
)

export default component.exports