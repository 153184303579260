<template>
  <div class="">
    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6">
            <input
              name="databaseBackupDescriptionAr"
              id="databaseBackupDescriptionAr"
              type="text"
              v-model="databaseBackupData.databaseBackupDescriptionAr"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="databaseBackupDescriptionAr">{{
              $t("general.descriptionAr")
            }}</label>
            <img src="@/assets/images/description.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <input
              name="databaseBackupDescriptionEn"
              id="databaseBackupDescriptionEn"
              type="text"
              v-model="databaseBackupData.databaseBackupDescriptionEn"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="databaseBackupDescriptionEn">{{
              $t("general.descriptionEn")
            }}</label>
            <img src="@/assets/images/description.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <input
              name="databaseBackupDescriptionUnd"
              id="databaseBackupDescriptionUnd"
              type="text"
              v-model="databaseBackupData.databaseBackupDescriptionUnd"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="databaseBackupDescriptionUnd">{{
              $t("general.descriptionUnd")
            }}</label>
            <img src="@/assets/images/description.svg" />
          </div>

          <TextArea
            :className="'col-md-6'"
            :id="'databaseBackupNotes'"
            :value="databaseBackupData.databaseBackupNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="databaseBackupData.databaseBackupNotes = $event"
          />
        </div>
        <CustomCheckbox
          :className="'col-md-12'"
          :value="databaseBackupData.autoDownload"
          :title="$t('general.autoDownload')"
          v-on:changeValue="databaseBackupData.autoDownload = $event"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addDatabaseBackup"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="{
            name: 'DatabaseBackups',
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import TextArea from "@/components/general/TextArea.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";

export default {
  name: "DatabaseBackupForm",
  components: {
    TextArea,
    CustomCheckbox,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["databaseBackupData", "submitName"],
  methods: {
    async addDatabaseBackup() {
      this.$emit("addDatabaseBackup");
    },
  },
};
</script>

<style scoped lang="scss"></style>
