export default class DatabaseBackupsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.textSearch = "";
    this.dateFrom = "";
    this.dateTo = "";
  }
  fillData(data) {
    this.textSearch = data.textSearch;
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
  }
}
